import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RichText from '~components/RichText';
import Container from '~components/Container';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    '& > .MuiExpansionPanel-root::before': {
      height: '0px'
    }
  },

  media: {
    maxWidth: '100%',
    display: 'flex',
    margin: '0  auto'
  },
  expansionPanel: {
    display: 'block',
    padding: '0px'
  },
  expansionSummarySpacing: {
    margin: '0px 0px',
    padding: '0px 0px',
    display: 'inline-flex'
  },
  buttonStyle: props => ({
    textTransform: 'none',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '30px',
    padding: '15px 52px',
    margin: '0 auto',
    marginTop: '50px',
    boxShadow: 'unset',
    backgroundColor: props.button_bg_color || '#458FCE',
    color: props.button_title_color || '#fff',
    '&:hover': {
      boxShadow: 'unset',
      backgroundColor: props.button_bg_color || '#458FCE',
      opacity: '0.8'
    }
  }),
  containerSpacing: {
    marginBottom: '100px',
    marginTop: '100px'
  }
}));

const AboutFaq = ({
  faq_bg_color,
  section_title,
  faqSections,
  button_bg_color,
  button_title,
  button_link,
  button_title_color
}) => {
  const classes = useStyles({ button_bg_color, button_title_color });
  const defaultExpandedPanel =
    faqSections && faqSections[0] && faqSections[0].topic_title && faqSections[0].topic_title.html;
  const [expanded, setExpanded] = React.useState(defaultExpandedPanel || '');

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container background={faq_bg_color}>
      <Box className={classes.containerSpacing}>
        <RichText
          html={section_title && section_title.html}
          mode="teamPageJobSectionTitle"
          verticalSpacing={0}
        />
        <div className={classes.root}>
          {faqSections &&
            faqSections.map(section => {
              const title = section && section.topic_title && section.topic_title.html;
              const content = section && section.topic_content && section.topic_content.html;

              return (
                <Accordion
                  expanded={expanded === title}
                  onChange={handleChange(title)}
                  style={{ boxShadow: 'unset', border: 'unset', backgroundColor: 'unset' }}
                  key={title}
                >
                  <AccordionSummary
                    aria-controls={title}
                    id={title}
                    className={classes.expansionSummarySpacing}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <RichText html={title} mode="faqTopicTitle" />
                  </AccordionSummary>
                  <AccordionDetails className={classes.expansionPanel}>
                    <RichText html={content} mode="faqTopicContent" verticalSpacing={0} />
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </div>
        <Button
          variant="contained"
          className={classes.buttonStyle}
          href={button_link && button_link.url}
          target="_blank"
        >
          {button_title}
        </Button>
      </Box>
    </Container>
  );
};

AboutFaq.propTypes = {
  faq_bg_color: PropTypes.string.isRequired,
  section_title: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  faqSections: PropTypes.arrayOf(
    PropTypes.shape({
      topic_title: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired,
      topic_content: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  button_bg_color: PropTypes.string.isRequired,
  button_title: PropTypes.string.isRequired,
  button_link: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  button_title_color: PropTypes.string.isRequired
};

export default AboutFaq;
